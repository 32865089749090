<template>

   <v-card elevation="0">
    <v-checkbox
      class="pa-2 openday checkDay"
      v-model="checked"
      off-icon="ri-checkbox-blank-line"
    >
      <template v-slot:label>
        星期{{ $helper.transformDate(date) }}
      </template>
    </v-checkbox>
    <div>
      <div class="ml-3 pa-2" v-if="!checked">
        <p class="ml-4 mb-2" >本日公休</p>
      </div>
  
      <div class="ml-3 pa-2" v-else>
        <div 
          v-for="(item, index) in times"
          :key="index"
          class="mb-8"
        >
          <v-select
          :items="timesConstant.start"
          label="開始"
          class="ml-4 mb-3 opendaySelect"
          hide-details
          outlined
          width="200"
          v-model="times[index]['start_time']"
          ></v-select>
          <div class="d-flex align-center mb-3">
            <v-select
              :items="timesConstant.end"
              label="結束"
              class="ml-4 opendaySelect"
              hide-details
              outlined
              width="200"
              v-model="times[index]['end_time']"
            ></v-select>
            <div class="ml-4" v-if="index == (times.length - 1)">
              <button  class="opendaySelect--add"  @click="addTimes"
                ><i class="ri-add-line"></i
              ></button>
            </div>
            <div class="ml-3" v-if="times.length > 1" @click="removeTimes(index)">
              <button href="" class="opendaySelect--delete"
                ><i class="ri-delete-bin-line"></i
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </v-card>
</template>

<script>
import mixins from "@/components/eaglePlugin/ItemMixins.js";
import timesConstant from '@/config/times.js';

export default {
  mixins: [mixins],
  data: () => ({
    checked : false,
    date : 0,
    times : []
  }),
  computed : {
    timesConstant () {
      return timesConstant;
    }
  },
  watch : {

  },
  methods : {
    addTimes() {
      this.times.push({
        start_time : "00:00",
        end_time : "00:30"
      })
    },
    removeTimes(index) {
      this.times.splice(index,1);
    }
  }
};
</script>