export default {
  props: {
    value: [Object, Array],
  },
  data: () => ({
    
  }),
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.value) return;
        Object.keys(this.$data).forEach((key) => {
          this.$data[key] = this.value[key];
        });
      },
    },
  },
  created() {
    Object.keys(this.$data).forEach((key) => {
      this.$watch(key, (newData) => {
        this.$emit('input',
          {
            ...this.value,
            ...{ [key]: newData }
          })
      });
    });

  },
}